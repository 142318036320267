.mint-transition-section {
  max-width: 600px;
  margin: 0 auto;
  height: 60vh;
  position: relative;
  .transitioning-section {
    height: 100%;
  }
}

.mint-gradient-title {
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(91.12deg, #6347F6 0.28%, #2B30F5 92.14%);
  background-position: 0% 0%;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: 'Inter', sans-serif;
  font-weight: 700;
  letter-spacing: -0.04em;
}

.mint-btn-cta {
  background: linear-gradient(91.12deg, #6347F6 0.28%, #2B30F5 92.14%);
  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 30px;
  color: #F2F0FF;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;

  &:hover {
    text-decoration: none;
    color: #F2F0FF;
  }
  &:disabled {
    opacity: 0.5;
    color: #a7a7a7;
    cursor: default;

    &:hover {
      color: #a7a7a7;
    }
  }

  &.success {
    background: #00ffe0;
    color: black;

    &:disabled {
      opacity: 1.0;
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }
}
