.TeamSection {
  width: 80%;
  
  > .GradientTitle {
    align-self: flex-start;
  }

  > .TeamHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 5vh;

    > .TeamMember > img {
      width: 220px;
    }
    
    > .TeamMember > p {
      font-size: 18px;
    }
  }

  > .TeamList {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .TeamMember {
    padding: 32px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    transition: scale .4s ease;

    > img {
      border-radius: 100%;
      width: 170px;
      max-width: 100%;
      margin-bottom: 16px;
      aspect-ratio: 1;
    }

    > h1 {
      font-weight: 700;
      font-size: 27px;
      word-break: break-all;
    }

    > p {
      font-size: 14px;
      background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }


    &:hover {
      scale: 1.1
    }
  }


  @media (max-width: 999px) {
    > .TeamList {
      grid-template-columns: repeat(2, 1fr);
    }

    .TeamMember {
      padding: 16px;
    }
  }

  @media (max-width: 399px) {
    > .TeamList {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}