.page.create-landing-page {
  padding-top: 0;
}

/*
.create-landing-page {
  // self
  top: -5vh;
  left: 0;
  right: 0;
  bottom: 0;
  height: 84vh;
  z-index: 1000;
  min-width: 100vw;
  max-height: 84vh;
  position: absolute;

  // children
  display: grid;
  overflow: hidden;
  font-size: .12vh;
  place-items: center;
  place-content: center;
  grid: 'crl arc crr' 1fr;

  a {
    border: 0;
    width: 70vh;
    height: 100%;
    grid-area: arc;
    cursor: pointer;
    position: relative;
    border-radius: 16.0285em;
  }

  a::after {
    top: 0;
    left: 0;
    right: 9%;
    bottom: -5%;
    content: '';
    position: absolute;
    border-radius: 16.0285em;
    background: 
      center bottom 
      / auto 105% 
      no-repeat 
      url(../../assets/landing-page/preview.gif);
  }

  span {
    border: 0;
    background: 0;
    font-weight: 700;
    margin-left: .6em;
    font-size: 18.69em;
    font-family: 'Inter', sans-serif; 
  }

  button {
    left: 0;
    top: 39em;
    bottom: 0;
    right: 11%;
    z-index: 1;
    margin: auto;
    display: flex;
    width: 280.82em;
    height: 51.86em;  
    overflow: hidden;
    position: absolute;
    align-items: center;
    justify-content: center;
    border-radius: 11.2738em;
    transform-origin: center;
    animation: pulse 1s infinite alternate both;
  }

  button:hover::before {
    background-image: url(../../assets/landing-page/pencil-black.svg);
  }
  
  button::before {
    content: '';
    width: 20em;
    height: 20em;
    background: center / cover no-repeat url(../../assets/landing-page/pencil-white.svg);
  }

  button::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    filter: blur(100px);
    transform-origin: center;
    background: linear-gradient(.25turn, #fffe, #fff0);
    animation: shine 1s ease-out infinite alternate both;
  }
  
  @keyframes shine {
    0% {
      transform: translateX(50%) rotate(.125turn);
    }

    100% {
      transform: translateX(-50%) rotate(.125turn);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(.98);
    }

    100% {
      transform: scale(1.02);
    }
  }

  .left-crowd,
  .right-crowd {
    height: 100%;
    filter: opacity(.85);
  }

  .left-crowd {
    width: 58vh;
    grid-area: crl;
    margin-top: 5vh;
    mask-image: linear-gradient(.25turn, #0000, #000);  
  }

  .right-crowd {
    width: 50vh;
    grid-area: crr;
    margin-top: 10vh;
    mask-image: linear-gradient(.25turn, #000, #0000);
  }
}
*/
