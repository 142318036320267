.community-guidelines-page {
  max-width: 100vw;
  min-width: 100vw;
  background: #000;
  padding-bottom: 10vh;
  font-size: min(calc(.5px + .05vh), .15vw);

  section {
    margin: auto;
    width: 100vw;
    padding: 2vh 4vh 0;
    cursor: default;
    font-size: 30em;
    max-width: 100vh;
  }
  
  h1 {
    margin-bottom: .5em;
    font-family: 'Space Mono', sans-serif; 
    font-size: 2.7em;
    font-weight: 900;
    letter-spacing: .04em;
    color: #80FFC2;
    line-height: 100%;
  }

  h2 {
    font-family: 'Space Mono';
    font-size: 1.5em;
    font-weight: bold;
    color: #7848FF;
    margin: 1em 0 .5em;
  }

  p, a, li {
    color: #C9BFD8;
    font-family: 'Poppins', sans-serif;
  }

  a {
    font-weight: 900;
    text-decoration: underline;
    transition: color .4s;
  }

  a:hover {
    color: #fff;
  }
}
