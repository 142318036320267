sup {
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

h4 {
  color: white;
}

.linkWithIcon {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
  margin: 24px 0;

  p {
    margin: 0;
    margin-left: 16px;
    font-size: 20px !important;
  }

  img {
    height: 40px;
    width: 40px;
  }
}

h3 {
  color: white;
}
