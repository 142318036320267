.Header {
  // position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 91;

  height: 110px;
  box-sizing: border-box;
  user-select: none;
  background: #000;
  padding: 35px 5% 35px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .Burger {
    display: none;
    position: absolute;
    right: 24px;
    height: 50px;
  }

  > .LinkContainer,
  > .LinkContainer > div {
    display: flex;
    gap: 24px;

    align-items: center;

    .svs-btn {
      line-height: 40px;
    }

    > a {
      color: #f2f0ff !important; // Required to override previously set global hover styles
    }

    > .DropdownLink {
      color: #f2f0ff !important;
      text-decoration: none !important; // Required to override previously set global hover styles

      > .HeaderDropdown {
        display: flex;
        position: absolute;
        flex-direction: column;

        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease, height 0.4s ease, visibility 0s 0.4s;

        > a {
          color: #5c5a75 !important; // Required to override previously set global hover styles

          &:hover {
            text-decoration: underline #5c5a75;
          }
        }
      }

      &:hover {
        .HeaderDropdown {
          transition: opacity 0.4s ease, height 0.4s ease;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 699px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    height: auto;
    padding: 25px 5% 25px 5%;

    > .LinkContainer {
      display: none;
      flex-direction: column;
      align-self: center;
      width: 80%;
      flex-grow: 1;
      margin-top: 5%;
      gap: 49px;

      > a {
        font-size: 22px;
        position: relative;

        &::after {
          position: absolute;
          bottom: -24px;
          left: 0;
          right: 0;

          content: '';
          height: 1px;
          background: #252433;
        }
      }

      .DropdownLink {
        font-size: 22px;
        position: relative;

        > span {
          cursor: pointer;
        }

        > .HeaderDropdown {
          transition: height 0.4s ease;
          position: relative;
          opacity: 1;
          visibility: visible;

          height: 0;
          overflow: hidden;
        }

        img {
          transition: transform 0.4s ease;
        }

        &.open img {
          transform: rotate(180deg);
        }

        &::after {
          position: absolute;
          bottom: -24px;
          left: 0;
          right: 0;

          content: '';
          height: 1px;
          background: #252433;
        }
      }

      > .IconContainer {
        // margin: auto 0 64px 0;

        img {
          width: 10vw;
          min-width: 20px;
        }
      }
    }

    > .Burger {
      display: block;
      height: 30px;
    }

    .Logo {
      width: 30px;
    }

    &.open {
      height: 100vh;

      > .LinkContainer {
        display: flex;
      }
    }
  }
}

.Header.fixed {
  position: fixed;
}

.full-height .Header.open {
  height: 100%;
}
