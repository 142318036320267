.ShowcaseSection {
  display: flex;
  flex-direction: column;
  width: 90%;

  > :not(:first-child) {
    margin-top: 10vh;
  }


  > div {
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    > img, > video {
      width: 45%;
    }

    > div {
      width: 45%;
      max-width: 350px;

      > .purpleTitle {
        font-size: 18px;
        background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
      }

      > .description {
        font-weight: 400;
        font-size: 20px;
        color: #5C5A75;
      }
    }
  }

  @media (max-width: 699px) {
    > div {
      flex-direction: column;
      gap: 32px;
      align-items: center;
      
      > div,
      > img,
      > video {
        width: 80%;
      }

      &:nth-of-type(n + 2) {
        flex-direction: column-reverse;
      }
    }
  }
}