@keyframes GradientTitleBackgroundMoving {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.GradientTitle {
  display: inline-block;
  font-weight: 700;
  font-size: 62px;
  background-image: linear-gradient(135deg, #ADD7FF 10%, #FFC3EB 25%, #FBFFC8 40%, #FBFFC8 50%, #ADD7FF 60%, #FFC3EB 75%, #FBFFC8 90%);
  background-size: 300%;
  animation: GradientTitleBackgroundMoving 10s linear infinite;
  background-position: 0% 0%;
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 699px) {
    font-size: 50px;
  }

  @media (max-width: 399px) {
    font-size: 37px;
  }
}