@import '../../../media-queries.scss';

.premint-faqs {
  display: flex;
  gap: 30px;
  justify-content: space-evenly;
  flex-wrap: wrap;

  .qs-card {
    width: 300px;
  }
  .qs-title {
    font-size: 18px;
    line-height: unset;
    margin-bottom: 4px;
  }
  .qs-body,
  .qs-list {
    color: #84829d;
    font-size: 14px;
  }

  .qs-list {
    margin-left: 0;
    padding-left: 5px;
    list-style-type: none;

    li::before {
      content: '•';
      padding-right: 5px;
    }
  }
}

@include respond-below(md) {
  .premint-faqs {
    display: block;

    .qs-card {
      margin-bottom: 50px;
      width: 100%;
    }
    .qs-body {
      color: #d4d2ed;
    }
  }
}
