.tokenproof-button {
  width: 350px;
  height: 60px;
  border: none;
  text-align: center;
  background: #2665ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: white;

  .tokenproof-button-icon {
    height: 50%;
    margin-right: 24px;
  }

  .tokenproof-button-label {
    width: 408px;
    height: 28px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    word-wrap: normal;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}
