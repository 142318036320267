@import './media-queries.scss';

main.full-height {
  flex: 1;
  position: static;
}

.main-con {
  transition: 0.6s;
  transition-property: filter;
  position: relative;
}

.app-blur {
  filter: blur(10px);
}

a {
  color: white;
  transition: color 0.15s;
}

a:hover {
  color: #5163f9;
}

.main-footer {
  bottom: 0;
  height: 6vh;
  z-index: 900;
  width: 100vw;
  display: flex;
  padding: 0 2vh;
  text-align: end;
  position: fixed;
  align-items: center;
  pointer-events: none;
  justify-content: flex-end;

  .footer-link {
    color: #aaa;
    font-size: 1.6vh;
    border-radius: 0.2vh;
    pointer-events: all;
    display: inline-block;
    text-decoration-color: inherit;
    font-family: 'Inter', sans-serif;
  }

  .footer-link:hover {
    color: #fff;
  }

  .footer-link:focus {
    color: #0ff;
  }

  .footer-link:nth-of-type(1) {
    width: 8em;
  }
  .footer-link:nth-of-type(2) {
    width: 6em;
  }
  .footer-link:nth-of-type(3) {
    width: 4em;
  }
}

.main-footer::after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 14vh;
  content: '';
  z-index: -1000;
  position: absolute;
  background: linear-gradient(#0000, hsl(220, 10%, 12%));
}

a {
  transition: 0.4s;
  letter-spacing: 0.02em;
  transition-property: text-decoration-color, color;
}

a:hover {
  color: inherit;
  text-decoration-color: #fff;
}

#modal-root {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
}

.intro-main-content {
  box-sizing: border-box;
  max-width: 1200px;
  width: 100%;
  padding: 40px;

  h1 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #80ffc2;
    cursor: default;
    font-size: 2.7em;
    font-weight: 900;
    line-height: 100%;
    margin-bottom: 0.5em;
    letter-spacing: 0.04em;
    font-family: 'Space Mono';
  }

  h2 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #7848ff;
    cursor: default;
    font-size: 1.5em;
    font-weight: bold;
    margin: 1em 0 0.5em;
    font-family: 'Space Mono';
  }

  p,
  li {
    color: #c9bfd8;
    font-size: 16px;
    font-family: 'Poppins';
  }

  p a {
    color: #c9bfd8;
  }
}

.terms-content {
  margin-bottom: 2em;
  h1 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #c9bfd8;
    cursor: default;
    font-size: 2.7em;
    font-weight: 900;
    line-height: 100%;
    margin-bottom: 0.5em;
    letter-spacing: 0.04em;
  }

  h2 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #c9bfd8;
    cursor: default;
    font-size: 1.5em;
    font-weight: bold;
    text-align: left;
    // margin: 1em 0 .5em;
  }

  h3 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #c9bfd8;
    cursor: default;
    font-size: 1.4em;
    font-weight: bold;
    text-align: left;
    // margin: 1em 0 .5em;
  }

  h4 {
    font-size: min(calc(0.5px + 0.05vh), 0.15vw);
    color: #c9bfd8;
    cursor: default;
    font-size: 1.3em;
    text-align: left;
    // margin: 1em 0 .5em;
  }

  p,
  li {
    color: #c9bfd8;
    font-size: 16px;
  }

  p a {
    color: #c9bfd8;
    text-decoration: underline;
  }
}

@font-face {
  font-family: 'avenir';
  src: local('Avenir Next, Regular'), url('./assets/fonts/avenir/Metropolis-Regular.otf');
}

@font-face {
  font-family: 'avenir';
  font-weight: bold;
  src: local('Avenir Next, Bold'), url('./assets/fonts/avenir/Metropolis-Bold.otf');
}

@font-face {
  font-family: 'druk';
  src: local('Druk Wide Medium'), url('./assets/fonts/druk/Druk Wide Medium.ttf');
}

@font-face {
  font-family: 'druk';
  font-weight: bold;
  src: local('Druk Wide Bold'), url('./assets/fonts/druk/Druk Wide Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./assets/fonts/inter/inter.var.woff2?v=3.19') format('woff2');
}

div.desktop,
p.desktop {
  display: block;
}

span.desktop {
  display: inline;
}

div.mobile,
span.mobile,
p.mobile {
  display: none;
}

@include respond-below(sm) {
  div.desktop,
  span.desktop,
  p.desktop {
    display: none;
  }

  div.mobile,
  p.mobile {
    display: block;
  }

  span.mobile {
    display: inline;
  }
}

.no-scroll {
  overflow: hidden;
}

.svs-cookie-popup {
  p {
    margin: 5px 0;
    font-weight: bold;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
  #rcc-decline-button {
    background-color: #fff !important;
    color: #000 !important;
  }
}
