.whitelist-checked-section {
  .success-message {
    color: #463BF5;
    text-transform: uppercase;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 25px;
  }
  .mint-countdown {
    margin-bottom: 20px;
  }
  .GradientTitle {
    font-size: 32px;
    line-height: 1.3em;
  }
  .mint-btn-cta {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    padding: 15px;
    display: inline-block;
  }
  .btn-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
