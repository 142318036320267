.AccordionContainer {
  width: 100%;
  background: #15141E;
  border-radius: 6px;
  padding: 20px 30px 0px 30px;

  > .Toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #F2F0FF;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 20px;
    cursor: pointer;

    > button {
      box-shadow: none;
      background: transparent;

      &::after {
        display: inline-block;
        border-right: 8px solid transparent;
        border-top: 8px solid #6347F6;
        border-left: 8px solid transparent;
        transition: transform .4s ease;
        content: '';
      }

      &.opened::after {
        transform: rotate(-180deg);
      }
    }
  }

  > .Content {
    transition: height .4s ease, padding .4s ease;
    overflow: hidden;
    color: #F2F0FF;
  }
}