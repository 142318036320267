.founder-container {
  --unit: min(.1vh, .2vw);
  width: calc(450 * var(--unit));
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: calc(20 * var(--unit));
}

.founder-rect-top {
  background-color: #67FFB6;
  width: calc(450 * var(--unit));
  height: calc(320 * var(--unit));
  border-top-left-radius: calc(20 * var(--unit));
  border-top-right-radius: calc(20 * var(--unit));
}

.founder-picture {
  margin-top: calc(-320 * var(--unit));
  width: calc(320 * var(--unit));
  height: calc(320 * var(--unit));
  border-radius: 50%;
  background-size: contain;
}

.founder-rect-bottom {
  margin-top: 0px;
  background: radial-gradient(52.27% 24.31% at -2.27% 78.32%, #702DFF 0%, rgba(122, 60, 255, 0) 100%), rgba(96, 58, 255, 0.23);
  backdrop-filter: blur(50.66px);
  padding: calc(50 * var(--unit));
  border-bottom-left-radius: calc(20 * var(--unit));
  border-bottom-right-radius: calc(20 * var(--unit));
  font-family: 'Space Mono';
  color: #FFFFFF;
}

.founder-name {
  font-size: calc(32 * var(--unit));
  color: #FFFFFF;
  text-decoration: underline;
}

.founder-title {
  font-size: calc(17 * var(--unit));
  color: #67FFB6;
}

.founder-desc {
  margin-top: calc(30 * var(--unit));
  font-family: 'Poppins';
  font-size: calc(15 * var(--unit));
}
