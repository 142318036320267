.premint-subpage {
  .mint-video-layout-video {
    position: relative;
  }
  .vimeo-react-player {
    width: 100%;
    height: 100%;

    iframe {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }
}
