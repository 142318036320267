.LandingSection {
  margin-top: 3vh;
  text-align: center;
  position: relative;
  gap: 32px;

  > .LandingImage {
    object-fit: contain;
    width: 1242px;
    // height: 502px;
    max-width: 100%;
    max-height: 50vh;
    font-size: 14px;
    image-rendering: crisp-edges;
  }

  > .Button {
    background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%) !important; // Required to override previously set global hover styles
    width: 315px;
    max-width: 100%;
    height: 50px;
    box-shadow: none;
    color: #F2F0FF;

    text-transform: uppercase;
    font-weight: 700;
    transition: transform .4s;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    &:hover {
      transform: scale(105%);
      text-decoration: none;
      color: #F2F0FF; // Required to override previously set global hover styles
    }
  }

  > .TextBodyLarge {
    color: #5C5A75;
    font-size: 23px;
    max-width: 80%;
  }

  > p {
    margin: 0;
    line-height: 1;
  }

  > h1 {
    font-weight: 800;
    margin: 0;
  }



  // Email Popup

  > .emailPopup {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: #000000e5;
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease, visibility 0s .4s;

    &.open{
      visibility: visible;
      opacity: 1;
      transition: opacity .4s ease;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 32px;

    > form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
  
      gap: 32px;

      > input {
        width: 500px;
        max-width: 80%;
        padding: 12px;

        background: transparent;
        border: none;
        border-bottom: 1px solid #252433;
        font-size: 16px;
        font-weight: 600;
        color: #F2F0FF;
        outline: none;
      }

      > input::placeholder {
        color: #5C5A75;
      }

      > div {
        > label {
          margin-left: 6px;
          color: #F2F0FF;
        }
      }

      > button {
        background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%) !important; // Required to override previously set global hover styles
        color: #F2F0FF !important;
        width: 190px;
        max-width: 100%;
        height: 50px;
        box-shadow: none;

        text-transform: uppercase;
        font-weight: 700;
        transition: transform .4s;

        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        border-radius: 10px;

        &:not(:disabled):hover {
          transform: scale(105%);
        }

        &:disabled {
          cursor: not-allowed;
          filter: brightness(.5);
        }
      }
    }
  }
}