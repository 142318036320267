.svs-input-text {
  input {
    width: 100%;
    color: #e6e5e5;
    font-size: 16px;
    background-color: #00000000;
    background-repeat: no-repeat;
    background-position: 17px 15px;
    background-attachment: scroll;
    padding-left: 50px;
    padding-right: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    border: 1px solid #292837;
    border-radius: 10px;
    box-sizing: border-box;
  }
  input::placeholder {
    color: #6c6a85;
  }
}
