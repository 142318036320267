.SecretMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 121, 121, 0.668);
  display: flex;
  z-index: 9999;
  overflow: hidden;
  padding: 10px;

  .f {
    display: flex;
    flex: 1;
  }

  .header {
    flex-direction: row;
    width: 100%;
    height: 50px;

    .close {
      justify-content: flex-end;
      flex: 0.1;

      button {
        width: 30px;
        height: 30px;
      }
    }

    .title {
      justify-content: center;
    }
  }
}
