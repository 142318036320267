.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: none;
}

@-webkit-keyframes spinner /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes spinner {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.spinner {
  width: 20%;
  opacity: 0.3;
  --anim: spinner 2s ease infinite;
  -webkit-animation: var(--anim);
  -moz-animation: var(--anim);
  -ms-animation: var(--anim);
  -o-animation: var(--anim);
  animation: var(--anim);
}