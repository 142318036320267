@import '../../../media-queries.scss';

.coll-info {
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  gap: 20px;
}

.coll-info-video {
  border-radius: 10px;
  width: 100%;
}

.coll-info-title {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: white;
  text-align: left;
}

.coll-info-description {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #d4d2ed;
  padding-top: 3px;

  vertical-align: middle;
  text-align: left;

  h3 {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: white;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.coll-info-minted {
  font-size: 16px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
}

.coll-info-minted-counter {
  color: white;
  display: inline-block;
  color: #7848ff;
}

.coll-info-link {
  color: white;
}

@include respond-below(sm) {
  .coll-info-title {
    margin-bottom: -18px;
    margin-top: -8px;
  }

  .coll-info-description {
    p {
      margin-bottom: 0px;
    }
  }
}
