@import '../../../media-queries.scss';

.coll-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.coll-layout-title {
  font-size: 50px;
  line-height: 63px;
  color: #7848ff;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.coll-layout-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 25px;
  padding-left: 3px;

  background-image: linear-gradient(103.68deg, #add7ff 19.74%, #ffc3eb 45.82%, #fbffc8 67.89%);
  background-size: 300%;
  background-position: 0% 0%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: coll-layout-subtitle-anim 10s linear infinite;
}

@include respond-below(md) {
  .coll-layout-header {
    margin-bottom: 0;
  }

  .coll-layout-title {
    font-size: 26px;
    line-height: 30px;
  }

  .coll-layout-subtitle {
    line-height: 20px;
    font-size: 15px;
  }
}

@keyframes coll-layout-subtitle-anim {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

// ====================================

.coll-layout-body {
  .coll-layout-columns {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: row;
  }

  .coll-layout-column {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: rgb(23, 23, 23, 1);
    color: #7848ff;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    min-width: 300px;

    &.left {
      background-color: rgb(23, 23, 23, 1);
      padding-top: 0;
    }

    &.right {
      background-color: rgb(23, 23, 23, 1);
    }
  }
}

@include respond-below(md) {
  .coll-layout-body {
    .coll-layout-columns {
      margin-bottom: 50px;
      flex-direction: column;
    }

    .coll-layout-column {
      flex-grow: 1;
      overflow: hidden;
      width: 100%;
      margin-bottom: 10px;
    }
    // -------------------
    .coll-form-box-input {
      flex-direction: column-reverse;
      margin-bottom: 0px;
    }

    .coll-form-column-input {
      overflow: hidden;
      width: 100%;

      &.left {
        width: 100%;
      }

      &.right {
        width: 100%;
        height: 35px;
        margin-bottom: -18px;
      }
    }
  }
  // -------------------

  .coll-form-column-connect {
    overflow: hidden;
    width: 100%;

    &.left {
      width: 30%;
      min-width: 30%;
    }

    &.right {
      width: 70%;
    }
  }
}
