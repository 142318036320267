.FaqSection {
  width: 80%;
  align-items: flex-start;
  overflow: hidden;
  
  > h1 {
    margin-bottom: 5vh;
  }

  > .AccordionContainer {
    margin: 8px 0;
  }

  > p {
    margin-top: 5vh;
    align-self: center;
    color: #84829D;
    font-size: 20px;
    text-align: center;
  }
}