.vimeo-react-player {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  > iframe {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 16 / 9;
    display: block;
  }
}
