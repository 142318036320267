.Section {
  margin: 15vh auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-of-type {
    margin-bottom: 15vh;
  }


  @media (max-width: 699px) {
    min-width: 90%;
  }



  button,
  img {
    user-select: none;
  }

  img {
    -webkit-user-drag: none;
  }
}