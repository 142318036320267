.collections-viewer-container {
  position: relative;
  margin-top: 50px;
  border-radius: 10px;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.collections-viewer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media only screen and (max-width: 699px) {
  .collections-viewer-container {
    width: 100%;
    padding-top: 130%;
    height: auto;
  }
}

.coll-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}

.coll-layout-title {
  font-size: 50px;
  line-height: 63px;
}

.coll-layout-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  line-height: 21px;
  padding-left: 3px;

  background-image: linear-gradient(103.68deg, #add7ff 19.74%, #ffc3eb 45.82%, #fbffc8 67.89%);
  background-size: 300%;
  background-position: 0% 0%;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: mint-layout-subtitle-anim 10s linear infinite;
}

.coll-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;
}

.c-column-left {
  width: 50%;
  height: 200px;
  background-color: grey;
}

.c-column-right {
  width: 50%;
  height: 200px;
  background-color: grey;
}

.c-stepbox {
  width: 50%;
  height: 200px;
  background-color: grey;
}
