@import '../../../media-queries.scss';

.signup-section {
  position: relative;

  .mint-countdown {
    margin-bottom: 20px;
  }
  // h3 {
  //   font-family: 'Inter', sans-serif;
  //   font-weight: 600;
  // }

  .GradientTitle {
    font-size: 32px;
    line-height: 1.3em;
  }

  .svs-input-text {
    margin-top: 20px;
  }

  .email-notice {
    font-size: 14px;
    color: #6c6a85;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .tokenproof-button {
    margin-top: 20px;
  }

  .error-message {
    font-size: 13px;
    color: #ff7777;
    margin-top: 30px;
  }
  .mint-btn-cta {
    margin-top: 20px;
    padding: 15px 0;
    width: 100%;
    font-size: 18px;
  }

  .step-container {
    margin-top: 20px;
    margin-bottom: 0;
  }

  @include respond-below(md) {
    .mint-countdown {
      margin-bottom: 10px;
    }
    .GradientTitle {
      font-size: 22px;
    }
    .svs-input-text {
      margin-top: 10px;
    }
    .email-notice {
      margin-top: 15px;
    }
    .tokenproof-button {
      margin-top: 15px;
    }
    .error-message {
      margin-top: 15px;
    }
  }
}
