.CarouselSection {
  min-height: 40vh;

  li.selected .slideContent:hover {
    transform: scale(105%);
    text-decoration: none;
  }

  .slideContent {
    padding: 20px;

    transition: transform 250ms ease-in-out;

    > img, > video {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      aspect-ratio: 1;
      width: 100%;
      display: block;
    }

    > .caption {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      padding: 20px 30px;
      background: #15141E;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;

      > p {
        margin: 0;
        font-size: 12px;
        color: #5C5A75;

        > a {
          font-size: 12px;
        }
      }
    }

  }

  > p {
    color: #5C5A75;
    font-size: 20px;
  }

  > a {
    margin-top: 20px;
    background: #15141E !important; // Required to override previously set global hover styles
    color: #F2F0FF !important; // Required to override previously set global hover styles
    user-select: none;
    width: 315px;
    max-width: 100%;
    height: 50px;
    border-radius: 10px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-weight: 700;
    transition: transform .4s;

    &:hover {
      transform: scale(105%);
      text-decoration: none;
    }

    > img, > video {
      margin-right: 8px;
    }
  }


  .carousel-root {
    margin-bottom: 10px;
    width: 100%;
    max-width: 900px;
  }

  .control-arrow {
    box-shadow: none;
  }

  .carousel .control-dots .dot.selected, .carousel .control-dots .dot {
    background: #252433;
    opacity: 1;
  }

  .carousel .control-dots .dot.selected, .carousel .control-dots .dot.selected {
    background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide {
    transition: scale .4s ease, opacity .4s ease;
  }

  .carousel .slider-wrapper.axis-horizontal .slider .slide:not(.selected) {
    scale: scale(.7);
    opacity: .2;
  }

  .carousel.carousel-slider .control-arrow {
    opacity: 1;
    height: 35px;
    width: 35px;
    top: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: transparent;
    }

    &::before {
      border: none;
      margin: 0;
      height: 16px;
      width: 16px;
      rotate: 45deg;
    }
  }

  .carousel .control-next.control-arrow {
    right: 10%;

    &::before {
      border-top: 3px solid #F2F0FF;
      border-right: 3px solid #F2F0FF;
      border-top-right-radius: 2px;
      // transform: translateX(-25%) translateY(25%);
    }

    &:hover{
      scale: 1.15;
    }
  }

  .carousel .control-prev.control-arrow {
    left: 10%;

    &::before {
      border-bottom: 3px solid #F2F0FF;
      border-left: 3px solid #F2F0FF;
      border-bottom-left-radius: 2px;
      // transform: translateX(25%) translateY(-25%);
    }
  }
}