@import '../../../media-queries.scss';

.mint-countdown {
  margin-bottom: 30px;
}

@include respond-below(md) {
  .mint-countdown {
    font-size: 14px;
  }
}
