.BurgerContainer {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-shadow: none;
  padding: 0;
  background: transparent !important;

  > .InnerBurger {
    position: relative;
    user-select: none;
    box-sizing: border-box;

    &,
    &::before,
    &::after {
      display: 'block';
      width: 24px;
      height: 2px;
      background-color: #F2F0FF;
      outline: 1px solid transparent;
      transition: background-color .4s, transform .3s;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      left: 0;
    }

    &::before {
      top: -8px;
    }

    &::after {
      top: 8px;
    }
  }


  > .opened {
    background-color: transparent;

    &::before {
      transform: translateY(8px) rotate(45deg);
    }

    &::after {
      transform: translateY(-8px) rotate(-45deg);
    }
  }
}