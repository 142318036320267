.ContactSection {
  width: 80%;
  > h1 {
    font-size: 27px;
    font-weight: 700;
    background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;

    > input,
    > textarea {
      width: 100%;
      background: transparent;
      border: none;
      font-size: 16px;
      font-weight: 600;
      color: #F2F0FF;
      resize: none;
      outline: none;
      padding: 12px;
    }

    > input::placeholder,
    > textarea::placeholder {
      color: #5C5A75;
    }

    > input {
      border-bottom: 1px solid #252433;
    }

    > textarea {
      border: 1px solid #252433;
      border-radius: 8px;
    }

    > button {
      background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%) !important; // Required to override previously set global hover styles
      color: #F2F0FF !important;
      width: 190px;
      max-width: 100%;
      height: 50px;
      box-shadow: none;

      text-transform: uppercase;
      font-weight: 700;
      transition: transform .4s;

      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      border-radius: 10px;

      &:not(:disabled):hover {
        transform: scale(105%);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
}