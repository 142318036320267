#overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; 
  background: transparent;
  color: transparent;
  z-index: 999999;
  opacity: 0.05;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

#logo {
  width: 15%;
  margin-bottom: 6vh;
}

h2 {
  text-align: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}
