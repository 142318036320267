.coll-mint-container {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  gap: 10px;
}

.coll-mint-price {
  display: flex;
  justify-content: space-between;
  margin: 0 5px;
}

.coll-mint-price-title {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  color: white;
}

.coll-mint-price-value-eth {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  color: white;
  text-align: right;
}

.coll-mint-price-timer {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 15px;
  color: rgb(148, 158, 158);

  &.expired {
    width: 100%;
    text-align: right;
  }
}

.coll-mint-price-value-dollar {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 15px;
  color: rgb(148, 158, 158);
  text-align: right;
}

.coll-mint-btn {
  &.white {
    background: white;

    &:hover {
      color: black;
    }
    &:disabled {
      opacity: 0.5;
      color: #222;
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }

  &.blue {
    background: linear-gradient(91.12deg, #6347f6 0.28%, #2b30f5 92.14%);
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 15px 30px;
    color: #f2f0ff;
    box-sizing: border-box;
    text-decoration: none;
    box-shadow: none;
  }

  width: 100%;
  margin: 10px auto;

  border-radius: 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 30px;
  color: #222;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
}

.coll-mint-gas {
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  line-height: 20px;
  color: rgb(148, 158, 158);
  text-align: center;
}
