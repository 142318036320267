.whitepaper-viewer-container {
  position: relative;
  margin-top: 50px;
  border-radius: 10px;
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

.whitepaper-viewer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media only screen and (max-width: 699px) {
  .whitepaper-viewer-container {
    width: 100%;
    padding-top: 130%;
    height: auto;
  }
}
