@import '../../../media-queries.scss';

.mint-video-layout {
  display: flex;
  align-items: stretch;
  gap: 20px;
  margin-bottom: 100px;
}

.mint-video-layout-video {
  flex-grow: 1;
  border-radius: 20px;
  overflow: hidden;
}

.mint-video-layout-content {
  width: 430px;
  background-color: #111018;
  border-radius: 20px;
  min-height: 400px;
  position: relative;

  .spinner-container {
    background-color: #00000076;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.mint-video-children-con {
  padding: 40px;
}

@include respond-below(md) {
  .mint-video-layout {
    display: block;
    margin-bottom: 50px;
  }

  .mint-video-layout-video {
    width: 100%;
    margin-bottom: 20px;
  }

  .mint-video-layout-content {
    width: 100%;
  }

  .mint-video-children-con {
    width: 100%;
  }
}

@include respond-below(xs) {
  .mint-video-layout-content {
    min-height: auto;
  }
  .mint-video-children-con {
    padding: 20px;
  }
}
