.RoadmapSection {
  > .RoadmapWrapper {
    display: grid;
    grid-auto-rows: 1fr;
    position: relative;
    align-items: center;
    margin-top: 64px;

    > .RoadmapProgressOuter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      width: 4px;
      border-radius: 60%;
      background: #2C2B2B;
      overflow: hidden;
    
      > .RoadmapProgressInner {
        position: absolute;
        max-height: 50%;
        width: 100%;
        border-radius: 20%;
        background: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
      }
    }

    > .RoadmapElement {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 80%;
      margin: 0 auto;

      > img {
        width: 40%;
      }

      > div {
        width: 40%;
        max-width: 750px;

        > h1 {
          font-size: 25px;
          font-weight: 700;
          color: #84829D;

          &.active {
            background-image: linear-gradient(135deg, #6347F6 0%, #2B30F5 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        > p {
          font-size: 20px;
          font-weight: 400;
          color: #5C5A75;
        }
      }

      > .svgCheckmark {
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        width: 20px;
      }
    }
  }

  > .moreToComeText {
    margin-top: 5vh;
    font-weight: 600;
    font-size: 25px;
    color: #5C5A75;
  }

  @media (max-width: 799px) {
    > .RoadmapWrapper {
      padding: 128px 0;
      gap: 128px;

      > .RoadmapProgressOuter  > .RoadmapProgressInner {
        max-height: 40%;
      }

      > .RoadmapElement {
        flex-direction: column-reverse;
        justify-content: flex-start;
        background: #0009;
        padding: 52px 0 32px 0;
        gap: 16px;
        box-shadow: 0 0 16px 24px #0009;

        > .svgCheckmark {
          top: 0%;
        }

        > div {
          width: 90%;
          text-align: center;
          max-width: unset;
        }

        > img {
          width: 90%;
        }
      }
    }
  }
}