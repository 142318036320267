@import '../../../media-queries.scss';

.mint-layout-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3em;

  .char-pass-dates-con {
    margin: 20px 65px 0;
  }
}

.mint-layout-title {
  font-size: 50px;
  line-height: 63px;
}

.mint-layout-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  line-height: 21px;
  padding-left: 3px;

  background-image: linear-gradient(103.68deg, #ADD7FF 19.74%, #FFC3EB 45.82%, #FBFFC8 67.89%);
  background-size: 300%;
  background-position: 0% 0%;
  background-clip: text;
  // text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: mint-layout-subtitle-anim 10s linear infinite;
}


@include respond-below(md) {
  .mint-layout-header {
    margin-bottom: 0;
  }

  .mint-layout-title {
    font-size: 30px;
    line-height: 30px;
  }
}

@keyframes mint-layout-subtitle-anim {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
