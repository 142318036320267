.Footer {
  z-index: 90;
  user-select: none;
  margin-bottom: 5vh;

  display: flex;
  align-items: flex-end;

  flex-grow: 1;

  a {
    text-decoration-color: #fff0;
    text-decoration-line: underline;
    transition: text-decoration-color 0.4s;
  }

  .FooterAlign {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    @media (max-width: 699px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      flex-direction: column-reverse;
      align-items: stretch;
      gap: 24px;

      > .LinkContainer {
        gap: 32px;
      }
    }

    @media (max-width: 399px) {
      > .LinkContainer {
        gap: 16px;
        justify-content: space-around;
        flex-wrap: wrap;
      }
    }
  }

  .LinkContainer {
    display: flex;
    justify-content: space-between;
    gap: 64px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > p {
        color: #d4d2ed;
        font-size: 15px;
        margin-bottom: 8px;
      }

      > a,
      > .clickable-text {
        color: #5c5a75;
        font-size: 12px;

        &:hover {
          text-decoration-color: #5c5a75;
        }
      }
      > .clickable-text:hover {
        cursor: pointer;
      }
    }
  }

  .Right {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > .char-pass-dates-con {
      margin-top: 0;
    }

    > .discord-only {
      color: #84829d;
      .mint-gradient-title {
        font-weight: 400;
      }
    }

    > .EmailSignup {
      > p {
        color: #d4d2ed;
        font-size: 15px;
        margin-bottom: 8px;
      }

      > form {
        display: flex;
        max-width: 100%;

        > input {
          border: 1px solid #252433;
          color: #f2f0ff;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          background: transparent;
          flex-grow: 1;
          max-width: calc(100% - 46px);
          padding: 10px 15px;
          outline: none;

          height: 46px;
        }

        > button {
          // Resets
          box-shadow: none;
          border-radius: 0;

          // Styles
          height: 46px;
          width: 46px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          background: linear-gradient(135deg, #6347f6 0%, #2b30f5 100%);

          // Arrow Centering
          display: flex;
          justify-content: center;
          align-items: center;

          &::after {
            display: inline-block;
            border-top: 8px solid transparent;
            border-left: 8px solid #f2f0ff;
            border-bottom: 8px solid transparent;
            transition: transform 0.4s ease;
            content: '';
          }
        }
      }
    }

    > .SocialLinks {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
}
