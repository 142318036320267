@import '../../../media-queries.scss';

.char-pass-dates-con {
  max-width: 430px;
  margin: 20px auto 0;
  .char-pass-dates {
    width: 300px;
    margin: 0 auto;
    .entry {
      display: flex;
      align-items: flex-end;
      gap: 2px;
    }
    .entry-spacer {
      flex-grow: 1;
      height: 1px;
      position: relative;
      top: -4px;
    }
    .GradientTitle {
      font-size: 18px;
    }
  }
}
.char-pass-dates-con-md {
  display: none;
  margin: 40px 0 40px;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;

  &.char-pass-dates-con-any {
    display: block;
  }

  .char-pass-dates {
    width: 100%;
  }
}

@include respond-below(md) {
  .char-pass-dates-con {
    display: none;

    &.char-pass-dates-con-any {
      display: block;
    }
  }
  .char-pass-dates-con-md {
    display: block;
  }
}
